import { combineReducers } from "redux";
import userReducer from './views/users/userReducer'
import deviceReducer from './views/devices/deviceReducer'
import notificationReducer from "./views/notificationsRastro/notificationReducer";
import reportReducer from "./views/reports/reportReducer"
import dashboardReducer from "./views/dashboard/dashboardReducer"
import mapaReducer from "./views/mapa/mapaReducer"
import maintenanceReducer from "./views/maintenances/maintenanceReducer";
import driverReducer from './views/drivers/driverReducer';
import configurationReducer from "./views/configurationsRastro/configurationReducer";
import fuelSupplyReducer from "./views/fuelSupply/fuelSupplyReducer";
import consumoReducer from "./views/consumo/consumoReducer";
import commandReducer from "./views/commands/commandReducer";
import risksReducer from "./views/risks/risksReducer";
import groupReducer from "./views/groups/groupReducer";
import financeiroReducer from "./views/financeiro/financeiroReducer";

const appReducer = combineReducers({
    //form: formReducer, //old redux-form
    user: userReducer,
    device: deviceReducer,
    notification: notificationReducer,
    report: reportReducer,
    dashboard: dashboardReducer,
    mapa: mapaReducer,
    maintenance: maintenanceReducer,
    driver: driverReducer,
    configuration: configurationReducer,
    fuelSupply: fuelSupplyReducer,
    consumo: consumoReducer,
    command: commandReducer,
    risks: risksReducer,
    group: groupReducer,
    financeiro: financeiroReducer,
})

export const initialState = {
    mapa: { loaded: false, ws: null, showDeviceNames: true },
    device: { devices: [], positions: [], position: [], positionDetail: { attributes: {}, }, deviceSummary: {}, deviceAddressSummary: '', deviceCRUD: {} },
    dashboard: { geofences: null, drivers: null, calendars: null },
    report: {
        reportType: {
            possibleFields: {
                'trips': [{
                        key: "deviceName",
                        label: "Dispositivo",
                    },
                    {
                        key: "maxSpeed",
                        label: "Velocidade Máxima",
                    },
                    {
                        key: "averageSpeed",
                        label: "Velocidade Média",
                    },
                    {
                        key: "distance",
                        label: "Distância",
                    },
                    {
                        key: "spentFuel",
                        label: "Combustível Gasto",
                    },
                    {
                        key: "duration",
                        label: "Duração",
                    },
                    {
                        key: "startTime",
                        label: "Início",
                    },
                    {
                        key: "startAddress",
                        label: "Local de Partida",
                    },
                    {
                        key: "endTime",
                        label: "Fim",
                    },
                    {
                        key: "endAddress",
                        label: "Local de Chegada",
                    },
                    {
                        key: "viewOnMap",
                        label: "Ver",
                    }
                ],
                'route': [{
                        key: "deviceId",
                        label: "Dispositivo",
                    },
                    {
                        key: "protocol",
                        label: "Protocolo",
                    },
                    {
                        key: "deviceTime",
                        label: "Horário",
                    },
                    {
                        key: "latitude",
                        label: "Latitude",
                    },
                    {
                        key: "longitude",
                        label: "Longitude",
                    },
                    {
                        key: "speed",
                        label: "Velocidade",
                    },
                    // {
                    //   key: "attributes",
                    //   label: "Informações Adicionais",
                    // },
                    {
                        key: "viewOnMap",
                        label: "Ver",
                    }
                ],
                'events': [{
                        key: "deviceId",
                        label: "Dispositivo",
                    },
                    {
                        key: "type",
                        label: "Tipo",
                    },
                    {
                        key: "eventTime",
                        label: "Horário",
                    },
                    // {
                    //   key: "maintenanceId",
                    //   label: "Manutenção",
                    // },
                    // {
                    //   key: "attributes",
                    //   label: "Informações Adicionais",
                    // },
                ],
                'daily': [{
                        key: "deviceName",
                        label: "Dispositivo",
                    },
                    {
                        key: "distance",
                        label: "Distância",
                    },
                    {
                        key: "averageSpeed",
                        label: "Velocidade Média",
                    },
                    {
                        key: "maxSpeed",
                        label: "Velocidade Máxima",
                    },
                    {
                        key: "spentFuel",
                        label: "Combustível Gasto",
                    },
                    //ocultei pq estamos usando o Total distance como odometro, pela facilidade de troca via TCPIp
                    // {
                    //   key: "startOdometer", 
                    //   label: "Km inicial",
                    // },
                    // {
                    //   key: "endOdometer",
                    //   label: "Km inicial",
                    // },
                    {
                        key: "startTime",
                        label: "Início",
                    },
                    {
                        key: "endTime",
                        label: "Final",
                    },
                    {
                        key: "engineHours",
                        label: "Tempo de Motor Ligado",
                    },
                ],
                'summary': [{
                        key: "deviceName",
                        label: "Dispositivo",
                    },
                    {
                        key: "maxSpeed",
                        label: "Velocidade Máxima",
                    },
                    {
                        key: "averageSpeed",
                        label: "Velocidade Média",
                    },
                    {
                        key: "distance",
                        label: "Distância",
                    },
                    {
                        key: "spentFuel",
                        label: "Combustível Gasto",
                    },
                    {
                        key: "engineHours",
                        label: "Tempo de Motor Ligado",
                    },
                ],
                'stops': [{
                        key: "deviceName",
                        label: "Dispositivo",
                    },
                    {
                        key: "duration",
                        label: "Duração",
                    },
                    {
                        key: "startTime",
                        label: "Início",
                    },
                    {
                        key: "address",
                        label: "Endereço",
                    },
                    {
                        key: "latitude",
                        label: "Latitude",
                    },
                    {
                        key: "longitude",
                        label: "Longitude",
                    },
                    {
                        key: "endTime",
                        label: "Fim",
                    },
                    {
                        key: "spentFuel",
                        label: "Combustível Gasto",
                    },
                    {
                        key: "engineHours",
                        label: "Tempo de Motor Ligado",
                    },
                    {
                        key: "viewOnMap",
                        label: "Ver",
                    },
                ],
            }
        }
    },
    notification: { notification: {}, notifications: [], notificationsByDevice: [], notificationsByUser: [] },
    user: { user: {}, userCRUD: {}, isGestorOuAdm: false, userGroups: [] },
    maintenance: { maintenance: {}, maintenances: [], maintenancesByDevice: [], maintenanceCRUD: {} },
    driver: { drivers: [], driverCRUD: {}, driversOnPositionDetail: [] },
    configuration: { cfg: { gasoline: 0, alcohol: 0, diesel: 0 } },
    fuelSupply: { fuelSupplies: [], fuelSupplyCRUD: {}, fuelSuppliesPositionDetail: [] },
    command: { commands: [], commandCRUD: {}, commandsManaged: [] },
    consumo: { consumos: [], engineFuelConsumption: { value: 12, label: "10km/l - valor default do sistema" } },
    group: { groups: [], groupCRUD: {} },
    financeiro: { extrato: { lancamentos: [], receita: 0.0, despesa: 0.0, balancoConcluido: 0.0, balancoPendente: 0.0 }, novoBoleto: {} },
}


const rootReducer = (state, action) => {
    if (action.type === 'USER_LOGGED_OUT') {
        return appReducer(initialState, action)
    }
    return appReducer(state, action)
}

export default rootReducer