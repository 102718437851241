const initialState = {
  commandCRUD: {}, //utilizada no crud do motorista
  commands: [], //lista dos motoristas  
  commandsTypes: [],
};

const commandReducer = (state = initialState, action) => {
  switch (action.type) {
    case "COMMAND_CRUD_CREATED":
      return { ...state, commandCRUD: action.payload };
    case "COMMAND_CRUD_UPDATED":
      return { ...state, commandCRUD: action.payload };
    case "COMMAND_CRUD_SETTED":
      return { ...state, commandCRUD: action.payload };

    case "COMMAND_CRUD_REMOVED":
      return { ...state, commandCRUD: action.payload };

    case "FETCH_COMMANDS_BY_USER":
      return {
        ...state,
        commands: action.payload,
      };
    case "FETCH_COMMANDS":
      return {
        ...state,
        commands: action.payload,
      };
    case "FETCH_COMMANDS_TYPES":
      return {
        ...state,
        commandsTypes: action.payload,
      };
    case "FETCH_COMMANDS_BY_USER_MANAGED":
      return {
        ...state,
        commands: action.payload,
      };
    case "FETCH_COMMANDS_BY_DEVICE_MANAGED":
      return {
        ...state,
        commands: action.payload,
      };
    default:
      return state;
  }
};

export default commandReducer;
