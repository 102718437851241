import thunk from 'redux-thunk'
import multi from 'redux-multi'
import promise from 'redux-promise'
import reducers from './reducers'
import { applyMiddleware, createStore } from 'redux';
import { baseURL } from './utils/util';

const saveToLocalStorage = (state) => {
  try {
    //console.log("tentando recarregar")
    localStorage.setItem('userState', JSON.stringify(state));
  } catch (e) {
    console.error(e);
  }
};

const loadFromLocalStorage = () => {
  try {
    console.log("pegando do disco")
    const stateStr = localStorage.getItem('userState');
    return stateStr ? JSON.parse(stateStr) : undefined;
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

export const persistedStore = loadFromLocalStorage();

const devTools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()

const store = applyMiddleware(multi, promise, thunk)(createStore)(reducers, persistedStore, devTools)

store.subscribe(() => {
  saveToLocalStorage(store.getState())
  console.log("salvando na store")
})

export default store


