const initialState = {
    extrato: [],
    novoBoleto: {}
};

const financeiroReducer = (state = initialState, action) => {
    switch (action.type) {

        case "FETCH_FINANCEIRO_EXTRATO":
            return {...state, extrato: action.payload };
        case "FETCH_FINANCEIRO_PIVOT":
            return {... state, pivot: action.payload};
        case "FETCH_FINANCEIRO_BOLETO":
            return {...state, novoBoleto: action.payload}
        default:
            return state;
    }
};

export default financeiroReducer;