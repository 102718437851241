const initialState = {
  driverss: [], //lista dos motoristas
  driverCRUD: {}, //utilizada no crud do motorista
  driversOnPositionDetail: [],
};

const driverReducer = (state = initialState, action) => {
  switch (action.type) {
    case "DRIVER_CRUD_CREATED":
      return { ...state, driverCRUD: action.payload };
    case "DRIVER_CRUD_UPDATED":
      return { ...state, driverCRUD: action.payload };
    case "DRIVER_CRUD_SETTED":
      return { ...state, driverCRUD: action.payload };

    case "DRIVER_CRUD_REMOVED":
      return { ...state, driverCRUD: action.payload };

    case "FETCH_DRIVERS_BY_USER":
      return {
        ...state,
        drivers: action.payload,
      };
    case "FETCH_DRIVERS_ON_POSITION_DETAIL":
      return {
        ...state,
        driversOnPositionDetail: action.payload,
      };

    default:
      return state;
  }
};

export default driverReducer;
