const initialState = {
  risks: []
};

const risksReducer = (state = initialState, action) => {
  switch (action.type) {
          
    case "FETCH_RISKS":
      return { ...state, risks: action.payload };
    
    case "FETCH_RISKS_INTERVAL":
        console.log("setando data")
        return { ...state, from: action.payload.from, to: action.payload.to };
      
    default:
      return state;

  }
};

export default risksReducer;
