
import React from "react";
export default function PinEncomenda(props) {
    return (
        <svg width="37" height="45" viewBox="0 0 26 34" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M13 0C5.83195 0 0 5.83195 0 13C0 15.1519 0.538002 17.2855 1.56076 19.1779L12.2891 33.5811C12.4319 33.8397 12.7041 34 13 34C13.296 34 13.5681 33.8397 13.7109 33.5811L24.4432 19.1715C25.462 17.2855 26 15.1518 26 12.9999C26 5.83195 20.1681 0 13 0Z" stroke="black" fill={props.fill}/>
            <path d="M15.375 8.70327V8.69952L18.1819 9.95671L21 8.90015L13.5 6.08765L6 8.90015L13.5 11.7126L16.3069 10.6598L13.5 9.50296V9.50015L15.375 8.70327Z" fill="white"/>
            <path d="M6 9.83766V18.2752L13.0312 20.9123V12.4748L6 9.83766ZM9.75 18.3061L7.875 17.603V16.6027L9.75 17.3058V18.3061Z" fill="white"/>
            <path d="M18.1875 10.8923V13.2408L16.3125 13.9439V11.5955L13.9688 12.4748V20.9123L21 18.2752V9.83766L18.1875 10.8923Z" fill="white"/>
        </svg>
    );
}