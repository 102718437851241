const initialState = {
  consumos: []
};

const consumoReducer = (state = initialState, action) => {
  switch (action.type) {
          
    case "FETCH_CONSUMOS_BY_USER":
      return { ...state, consumos: action.payload };      
    
    case "FETCH_ENGINE_FUEL_CONSUMPTION":
      return { ...state, engineFuelConsumption: action.payload};
    
    default:
      return state;

  }
};

export default consumoReducer;
