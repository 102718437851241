import React from "react";
export default function PinPet(props) {
    return (
        <svg width="37" height="45" viewBox="0 0 26 34" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M13 0C5.83195 0 0 5.83195 0 13C0 15.1519 0.538002 17.2855 1.56076 19.1779L12.2891 33.5811C12.4319 33.8397 12.7041 34 13 34C13.296 34 13.5681 33.8397 13.7109 33.5811L24.4432 19.1715C25.462 17.2855 26 15.1518 26 12.9999C26 5.83195 20.1681 0 13 0Z" stroke="black" fill={props.fill}/>
            <path d="M16.8315 14.0936C15.755 12.7938 14.3943 12.078 13 12.078C11.6057 12.078 10.245 12.7938 9.16853 14.0936C8.20094 15.2618 7.62328 16.7342 7.62328 18.0322C7.62328 18.6627 7.81603 19.1429 8.19616 19.4596C8.57135 19.7723 9.06669 19.878 9.61931 19.878C10.2073 19.878 10.8603 19.7582 11.5018 19.6405C12.0624 19.5377 12.5919 19.4406 13 19.4406C13.3523 19.4406 13.8489 19.5328 14.3747 19.6303C15.6033 19.8585 16.9959 20.117 17.8004 19.4481C18.1828 19.1301 18.3767 18.6537 18.3767 18.0322C18.3768 16.7342 17.7991 15.2618 16.8315 14.0936Z" fill="white" />
            <path d="M16.9416 7.03581C16.5477 6.45509 15.9837 6.12206 15.3942 6.12206C14.8046 6.12206 14.2406 6.45512 13.8467 7.03581C13.4849 7.56927 13.2857 8.26984 13.2857 9.00846C13.2857 9.74709 13.4849 10.4476 13.8467 10.9811C14.2406 11.5618 14.8046 11.8949 15.3942 11.8949C15.9837 11.8949 16.5477 11.5618 16.9416 10.9811C17.3034 10.4476 17.5027 9.74709 17.5027 9.00846C17.5027 8.26984 17.3034 7.56924 16.9416 7.03581Z" fill="white" />
            <path d="M12.1533 7.03577C11.7594 6.45506 11.1954 6.12202 10.6059 6.12202C10.0163 6.12202 9.45231 6.45509 9.05847 7.03577C8.69666 7.56924 8.49738 8.26981 8.49738 9.00843C8.49738 9.74706 8.69663 10.4476 9.05847 10.9811C9.45234 11.5618 10.0163 11.8949 10.6059 11.8949C11.1954 11.8949 11.7594 11.5618 12.1533 10.9811C12.5151 10.4476 12.7144 9.74709 12.7144 9.00843C12.7143 8.26981 12.5151 7.56921 12.1533 7.03577Z" fill="white" />
            <path d="M20.9068 11.5667C20.748 11.0265 20.4077 10.6341 19.9485 10.4616C19.579 10.3228 19.162 10.3464 18.7741 10.5279C18.2396 10.778 17.8027 11.2945 17.5755 11.9449C17.3898 12.4764 17.3667 13.0382 17.5103 13.5269C17.6691 14.067 18.0094 14.4595 18.4686 14.6319C18.6275 14.6916 18.7952 14.7213 18.9656 14.7213C19.1914 14.7213 19.422 14.6691 19.6431 14.5656C20.1776 14.3155 20.6145 13.799 20.8417 13.1486V13.1486C21.0274 12.6171 21.0505 12.0553 20.9068 11.5667Z" fill="white" />
            <path d="M8.42459 11.9448C8.19737 11.2944 7.76046 10.778 7.22596 10.5278C6.83805 10.3463 6.42093 10.3228 6.05146 10.4615C5.59227 10.634 5.25193 11.0265 5.09315 11.5666C4.94949 12.0553 4.97265 12.6171 5.1583 13.1485V13.1486C5.38555 13.799 5.82243 14.3154 6.35693 14.5656C6.57805 14.669 6.80865 14.7212 7.03449 14.7212C7.20487 14.7212 7.37255 14.6915 7.53143 14.6318C7.99068 14.4593 8.33099 14.0669 8.48977 13.5268C8.63337 13.0382 8.61021 12.4764 8.42459 11.9448Z" fill="white" />
        </svg>
    );
}