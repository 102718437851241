const initialState = {
  sidebarShow: "responsive",
  loading: false,
  user: {}, //User da autenticação
  users: [], //lista dos Users
  userCRUD: {}, //User do cadastro e manipulação da lista dos Users  
  userGroups: [], //lista dos Grupos do usuário
  isGestorOuAdm: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_LOADING":
      return { ...state, loading: action.payload };
    case "USER_LOGGED_OUT":
        console.log(initialState);
        return  initialState;
    case "ADD_USER":
      return { ...state, user: action.payload };
    case "USER_SETTED":
      return { ...state, user: action.payload };    
    case "USER_DO_LOGIN":
        state = initialState
      return { ...state, user: action.payload };
      
    case "USER_CRUD_CREATED":
        return { ...state, userCRUD: action.payload };
    case "USER_CRUD_UPDATED":
        return { ...state, userCRUD: action.payload };      
    case "USER_CRUD_SETTED":
      return { ...state, userCRUD: action.payload };      
    
    case "USER_CRUD_REMOVED":
      return { ...state, userCRUD: action.payload };      
    
    case "SET_GESTOR_OU_ADM":
      return  { ...state, isGestorOuAdm: action.payload}

    case "SET_SIDEBAR": //realiza o controle perefencial do usuario quanto ao sidemenu
      return { ...state, sidebarShow: action.payload }        
    case "FETCH_USERS":
      return {
        ...state, users: action.payload
      }

    case "FETCH_GROUPS":
      return { ...state, userGroups: action.payload };
    
    
    default:
      return state;
  }
};

export default userReducer;
