const initialState = {
  notificationCRUD: {},
  notification: {},
  notifications: [], // lista da notification do usuario
  notificators: [], // listas de possíveis notificators [firebase, sms, web, mail ...]
  notificationTypes: [], //lista de possíveis notifications sistema [ online, ignition, ...]
  notificationsByDevice: [],
  notificationsByUser: [], // usando somente em USER gerenciando outro user
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_NOTIFICATIONS_BY_USER":
      return { ...state, notifications: action.payload };
    case "FETCH_NOTIFICATIONS_BY_DEVICE":
      return { ...state, notificationsByDevice: action.payload };
    case "FETCH_NOTIFICATIONS_BY_USER_MANAGED":
      return { ...state, notificationsByUser: action.payload };

    case "NOTIFICATION_CRUD_SETTED":
      return { ...state, notificationCRUD: action.payload };
    case "NOTIFICATION_CRUD_REMOVED":
      return { ...state, notificationCRUD: action.payload };
    case "NOTIFICATION_CRUD_UPDATED":
      return { ...state, notificationCRUD: action.payload };
    case "NOTIFICATION_CRUD_CREATED":
      return { ...state, notificationCRUD: action.payload };
    case "FETCH_NOTIFICATORS":
      return { ...state, notificators: action.payload };
    case "FETCH_NOTIFICATIONS_TYPES":
      return { ...state, notificationTypes: action.payload };
    default:
      return state;
  }
};

export default notificationReducer;
