const initialState = {
  reportType: {
    toH: "23:59", fromH: "00:00", 
    possibleFields:
    {
      'trips': [
        {
          key: "deviceName",
          label: "Dispositivo",
        },
        {
          key: "maxSpeed",
          label: "Velocidade Máxima",
        },
        {
          key: "averageSpeed",
          label: "Velocidade Média",
        },
        {
          key: "distance",
          label: "Distância",
        },
        {
          key: "spentFuel",
          label: "Combustível Gasto",
        },
        {
          key: "duration",
          label: "Duração",
        },
        {
          key: "startTime",
          label: "Início",
        },
        {
          key: "startAddress",
          label: "Local de Partida",
        },
        {
          key: "endTime",
          label: "Fim",
        },
        {
          key: "endAddress",
          label: "Local de Chegada",
        },
        {
          key: "viewOnMap",
          label: "Ver",
        }
      ],
      'route': [
        {
          key: "deviceId",
          label: "Dispositivo",
        },
        {
          key: "protocol",
          label: "Protocolo",
        },
        {
          key: "deviceTime",
          label: "Horário",
        },
        {
          key: "latitude",
          label: "Latitude",
        },
        {
          key: "longitude",
          label: "Longitude",
        },
        {
          key: "speed",
          label: "Velocidade",
        },
        {
          key: "viewOnMap",
          label: "Ver",
        }
      ],
      'events': [
        {
          key: "deviceId",
          label: "Dispositivo",
        },
        {
          key: "type",
          label: "Tipo",
        },
        {
          key: "eventTime",
          label: "Horário",
        },
      ],
      'daily': [
        {
          key: "deviceName",
          label: "Dispositivo",
        },
        {
          key: "distance",
          label: "Distância",
        },
        {
          key: "averageSpeed",
          label: "Velocidade Média",
        },
        {
          key: "maxSpeed",
          label: "Velocidade Máxima",
        },
        {
          key: "spentFuel",
          label: "Combustível Gasto",
        },
        {
          key: "startTime",
          label: "Início",
        },
        {
          key: "endTime",
          label: "Final",
        },
        {
          key: "engineHours",
          label: "Tempo de Motor Ligado",
        },
      ],
      'summary': [
        {
          key: "deviceName",
          label: "Dispositivo",
        },
        {
          key: "maxSpeed",
          label: "Velocidade Máxima",
        },
        {
          key: "averageSpeed",
          label: "Velocidade Média",
        },
        {
          key: "distance",
          label: "Distância",
        },
        {
          key: "spentFuel",
          label: "Combustível Gasto",
        },
        {
          key: "engineHours",
          label: "Tempo de Motor Ligado",
        },
      ],
      'stops': [
        {
          key: "deviceName",
          label: "Dispositivo",
        },
        {
          key: "duration",
          label: "Duração",
        },
        {
          key: "startTime",
          label: "Início",
        },
        {
          key: "address",
          label: "Endereço",
        },
        {
          key: "latitude",
          label: "Latitude",
        },
        {
          key: "longitude",
          label: "Longitude",
        },
        {
          key: "endTime",
          label: "Fim",
        },
        {
          key: "spentFuel",
          label: "Combustível Gasto",
        },
        {
          key: "engineHours",
          label: "Tempo de Motor Ligado",
        },
        {
          key: "viewOnMap",
          label: "Ver",
        },
      ],
    }
  },
  report: {},
  reports: [],
  selectedFields: null,
};

const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REPORT_CRUD_SETTED":
      return { ...state, reportType: action.payload };
    case "FETCH_REPORTS_BY_USER": {
      console.log("carregou o state no reducer")
      return { ...state, reports: action.payload.reports, selectedFields: action.payload.selectedFields, reportType: action.payload.reportType };
    }
    case "FETCH_TRIP_ON_MAP":
      console.log(action)
      return { ...state, trip: action.payload.trip, pose: action.payload.pose, iniPose: action.payload.iniPose, endPose: action.payload.endPose };
    case "FETCH_POSE_ON_MAP":
      return { ...state, trip: null, pose: action.payload.pose };
    default:
      return state;
  }
};

export default reportReducer;
