import React from "react";
export default function PinCaminhao(props) {
    return (
        <svg width="37" height="45" viewBox="0 0 26 34" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M13 0C5.83195 0 0 5.83195 0 13C0 15.1519 0.538002 17.2855 1.56076 19.1779L12.2891 33.5811C12.4319 33.8397 12.7041 34 13 34C13.296 34 13.5681 33.8397 13.7109 33.5811L24.4432 19.1715C25.462 17.2855 26 15.1518 26 12.9999C26 5.83195 20.1681 0 13 0Z" stroke="black" fill={props.fill}/>
            <path d="M6.975 15.9932C7.77178 15.9932 8.42 16.6416 8.42 17.4382C8.42 18.235 7.77178 18.8832 6.975 18.8832C6.17839 18.8832 5.53 18.235 5.53 17.4382C5.53 16.6416 6.17839 15.9932 6.975 15.9932ZM6.975 17.8632C7.20942 17.8632 7.4 17.6726 7.4 17.4382C7.4 17.2038 7.20942 17.0132 6.975 17.0132C6.74058 17.0132 6.55 17.2038 6.55 17.4382C6.55 17.6726 6.74058 17.8632 6.975 17.8632Z" fill="white" />
            <path d="M7.11339 9.9866H9.61C9.79786 9.9866 9.95 10.1389 9.95 10.3266V16.9566C9.95 17.1443 9.79786 17.2966 9.61 17.2966H9.24942C9.08111 17.2966 8.93203 17.1754 8.91028 17.0085C8.77683 15.9861 7.96133 15.352 6.97483 15.352C5.98833 15.352 5.17283 15.9861 5.03939 17.0085C5.01764 17.1754 4.86872 17.2966 4.70025 17.2966H4.34003C4.15236 17.2966 4.00003 17.1443 4.00003 16.9566V13.6416C4.00003 13.5617 4.02808 13.4845 4.07925 13.4235L6.85264 10.1085C6.91719 10.0313 7.01275 9.9866 7.11339 9.9866ZM9.27 12.7066V11.0066C9.27 10.8189 9.11786 10.6666 8.93 10.6666H7.43231C7.3315 10.6666 7.23578 10.7115 7.17119 10.789L5.75458 12.489C5.57014 12.7105 5.72755 13.0466 6.01586 13.0466H8.93C9.11786 13.0466 9.27 12.8945 9.27 12.7066Z" fill="white" />
            <path d="M20.66 17.2968H19.7894C19.6211 17.2968 19.472 17.1755 19.4503 17.0086C19.3168 15.9862 18.5013 15.3522 17.5148 15.3522C16.5283 15.3522 15.7128 15.9863 15.5794 17.0086C15.5576 17.1756 15.4087 17.2968 15.2403 17.2968H10.97C10.7823 17.2968 10.63 17.1444 10.63 16.9568V8.45678C10.63 8.26911 10.7823 8.11678 10.97 8.11678H20.66C20.8479 8.11678 21 8.26911 21 8.45678V16.9568C21 17.1445 20.8479 17.2968 20.66 17.2968Z" fill="white" />
            <path d="M17.515 15.9932C18.3118 15.9932 18.96 16.6416 18.96 17.4382C18.96 18.235 18.3118 18.8832 17.515 18.8832C16.7184 18.8832 16.07 18.235 16.07 17.4382C16.07 16.6416 16.7184 15.9932 17.515 15.9932ZM17.515 17.8632C17.7494 17.8632 17.94 17.6726 17.94 17.4382C17.94 17.2038 17.7494 17.0132 17.515 17.0132C17.2806 17.0132 17.09 17.2038 17.09 17.4382C17.09 17.6726 17.2806 17.8632 17.515 17.8632Z" fill="white" />
        </svg>

    );
}