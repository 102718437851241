const initialState = {
    drivers: [],
    geofences: [],
    maintenances: [],
    calendars: [],
};

const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_DRIVERS":
            //console.log("está mandando drivers"+JSON.stringify(action.payload))
            return { ...state, drivers: action.payload };
        case "FETCH_GEOFENCES":
            //console.log("está mandando geofences"+JSON.stringify(action.payload))
            return { ...state, geofences: action.payload };
        case "FETCH_MAINTENANCES":
            return { ...state, maintenances: action.payload };
        case "FETCH_CALENDARS":
            return { ...state, calendars: action.payload };
        default:
            return state;
    }
};

export default dashboardReducer;
