
const initialState = {
   cfg: {gasoline: 0, alcohol: 0, diesel: 0}
};

const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_CONFIGURATION":
            return { ...state, cfg : action.payload };       
        default:
            return state;
    }
};

export default dashboardReducer;
