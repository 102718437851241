const initialState = {
    fuelSupplies: [],
    fuelSupplyCRUD: {},
    fuelSuppliesPositionDetail: []
  };
  
  const fuelSupplyReducer = (state = initialState, action) => {
    switch (action.type) {      
      
      case "FETCH_SUPPLY_CRUD_SETTED":
        return { ...state, fuelSupplyCRUD: action.payload };
      case "FETCH_SUPPLY_CRUD_REMOVED":
        return { ...state, fuelSupplyCRUD: action.payload };
      case "FETCH_SUPPLY_CRUD_UPDATED":
        return { ...state, fuelSupplyCRUD: action.payload };
      case "FETCH_SUPPLY_CRUD_CREATED":
        return { ...state, fuelSupplyCRUD: action.payload };
      
      case "FETCH_FUEL_SUPPLIES":
        return { ...state, fuelSupplies: action.payload };
      
      case "FETCH_FUEL_SUPPLIES_POSITION_DETAIL":
        return { ...state, fuelSuppliesPositionDetail: action.payload };
      default:
        return state;
    }
  };
  
  export default fuelSupplyReducer;
  