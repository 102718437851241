const initialState = {
  deviceAddressSummary: "",
  deviceSummary: {},
  deviceCRUD: {},
  device: {},
  devices: [],  
  position: {},
  positions: [],
  positionDetail: { attributes: {}},
};

const deviceReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ADDRESS_SUMMARY":
      return { ...state, deviceAddressSummary: action.payload };
    case "FETCH_ADDRESS_SUMMARY":
      return { ...state, deviceAddressSummary: action.payload };
    case "FETCH_DEVICE_SUMMARY":
      //console.log(`passei fectchando ` + JSON.stringify(action.payload))
      return { ...state, deviceSummary: action.payload };
    case "DEVICE_CRUD_SETTED":
      return { ...state, deviceCRUD: action.payload };
    case "DEVICE_CRUD_REMOVED":
      return { ...state, deviceCRUD: action.payload };
    case "DEVICE_CRUD_UPDATED":
      return { ...state, deviceCRUD: action.payload };
    case "DEVICE_CRUD_CREATED":
      return { ...state, deviceCRUD: action.payload };
      
    case "FETCH_DEVICES_BY_USER":
      return { ...state, devices: action.payload };      
    case "ADD_DEVICE":
      return { ...state, device: action.payload };
    case "GET_DEVICE":
      return { ...state, device: action.payload };
    case "CHANGE_POSITION":
      return { ...state, position: action.payload };
    case "FETCH_POSITIONS":
      return { ...state, positions: action.payload };
    case 'FETCH_POSITION_DETAIL':
      return { ...state, positionDetail: action.payload };
    
    case "ORDER_DEVICES":
      return { ...state, devices: action.payload };

    default:
      return state;
  }
};

export default deviceReducer;
