
import React from "react";
export default function PinPessoa(props) {
    return (
        <svg width="37" height="45" viewBox="0 0 26 34" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M13 0C5.83194 0 0 5.83195 0 13C0 15.1519 0.538001 17.2855 1.56076 19.1779L12.2891 33.5811C12.4319 33.8397 12.7041 34 13 34C13.2959 34 13.5681 33.8397 13.7109 33.5811L24.4432 19.1715C25.462 17.2855 26 15.1518 26 12.9999C26 5.83195 20.1681 0 13 0Z"  stroke="black" fill={props.fill} />
            <g clipPath="url(#clip0)">
                <path d="M12.9002 11.7438C13.8267 11.7438 14.629 11.4115 15.2845 10.7559C15.94 10.1004 16.2722 9.2984 16.2722 8.37181C16.2722 7.44555 15.94 6.6434 15.2844 5.98768C14.6287 5.33229 13.8266 5 12.9002 5C11.9736 5 11.1716 5.33229 10.5161 5.98779C9.86058 6.64329 9.52818 7.44544 9.52818 8.37181C9.52818 9.2984 9.86058 10.1006 10.5162 10.7561C11.1718 11.4114 11.9739 11.7438 12.9002 11.7438V11.7438Z" fill="white" />
                <path d="M18.8004 15.7653C18.7815 15.4925 18.7432 15.1949 18.6869 14.8807C18.6301 14.5641 18.5569 14.2648 18.4694 13.9912C18.3789 13.7085 18.2558 13.4293 18.1037 13.1618C17.9459 12.884 17.7605 12.6422 17.5524 12.4432C17.3348 12.2351 17.0684 12.0677 16.7604 11.9456C16.4534 11.8242 16.1132 11.7626 15.7493 11.7626C15.6064 11.7626 15.4682 11.8213 15.2013 11.9951C15.037 12.1022 14.8448 12.2261 14.6303 12.3631C14.4469 12.48 14.1985 12.5895 13.8916 12.6886C13.5922 12.7855 13.2883 12.8346 12.9882 12.8346C12.6882 12.8346 12.3843 12.7855 12.0846 12.6886C11.7781 12.5896 11.5296 12.4801 11.3464 12.3632C11.134 12.2275 10.9417 12.1036 10.775 11.9949C10.5084 11.8212 10.3701 11.7625 10.2272 11.7625C9.86314 11.7625 9.52305 11.8242 9.21618 11.9457C8.90835 12.0676 8.64186 12.235 8.42407 12.4433C8.21611 12.6424 8.03058 12.8842 7.87293 13.1618C7.72093 13.4293 7.59789 13.7084 7.50731 13.9914C7.41983 14.2649 7.34667 14.5641 7.28984 14.8807C7.23355 15.1945 7.19531 15.4922 7.17641 15.7656C7.15782 16.0335 7.14842 16.3115 7.14842 16.5923C7.14842 17.3231 7.38074 17.9147 7.83885 18.3511C8.29131 18.7816 8.88998 19 9.61801 19H16.3591C17.0871 19 17.6856 18.7817 18.1381 18.3511C18.5964 17.9151 18.8287 17.3233 18.8287 16.5922C18.8286 16.3101 18.8191 16.0319 18.8004 15.7653V15.7653Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="14" height="14" fill="white" transform="translate(5.99999 5)" />
                </clipPath>
            </defs>
        </svg>
    );
}



