const initialState = {
  loaded: false,
  showDeviceNames: true,
};

const mapaReducer = (state = initialState, action) => {
  switch (action.type) {
    case "WEBSOCKET_RUNNING":
      return { ...state, loaded: action.payload.loaded, ws: action.payload.ws };
    case "FETCH_COMMANDS_BY_DEVICE":
      return { ...state, comandos: action.payload};
    case "SET_SHOW_DEVICE_NAMES":
      console.log('MEU VALOR: ' + action.payload)
      return { ...state, showDeviceNames: action.payload};
    default:
      return state;
  }
};

export default mapaReducer;
