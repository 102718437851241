const initialState = {
    groups: [], //lista
    groupCRUD: {}, 
  };
  
  const groupReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GROUP_CRUD_CREATED":
        return { ...state, groupCRUD: action.payload };
      case "GROUP_CRUD_UPDATED":
        return { ...state, groupCRUD: action.payload };
      case "GROUP_CRUD_SETTED":
        return { ...state, groupCRUD: action.payload };
  
      case "GROUP_CRUD_REMOVED":
        return { ...state, groupCRUD: action.payload };
  
      case "FETCH_GROUPS_BY_USER":
        return {
          ...state,
          groups: action.payload,
        };
  
      default:
        return state;
    }
  };
  
  export default groupReducer;
  