import PinMoto from "../svg_pins/PinMoto";
import PinBarco from "../svg_pins/PinBarco";
import PinCar from "../svg_pins/PinCar";
import PinPet from "../svg_pins/PinPet";
import PinPessoa from "../svg_pins/PinPessoa";
import PinEncomenda from "../svg_pins/PinEncomenda";
import PinCaminhao from "../svg_pins/PinCaminhao";
import PinPadrao from "../svg_pins/PinPadrao";
import PinSeta from "../svg_pins/PinSeta";
import { renderToStaticMarkup } from "react-dom/server";
import { divIcon } from "leaflet";
import { CCol, CCard, CCardBody, CRow } from "@coreui/react";
import ReactLoading from "react-loading";
import moment from "moment";
import { CSpinner } from "@coreui/react";

//retirar proxy e mudar
//export const baseURL = "https://rastro.argovixmonitoramento.com.br";
export const baseURL = "";

//retorna o marcador pela categoria e status passadas SVG
export const getRequiredSVGPinByCategory = (category, myStyle) => {
  let pin;
  switch (category) {
    case "animal":
      pin = <PinPet {...myStyle} />;
      break;
    case "boat":
      pin = <PinBarco {...myStyle} />;
      break;
    case "car":
      pin = <PinCar {...myStyle} />;
      break;
    case "motorcycle":
      pin = <PinMoto {...myStyle} />;
      break;
    case "person":
      pin = <PinPessoa {...myStyle} />;
      break;
    case "ship":
      pin = <PinBarco {...myStyle} />;
      break;
    case "truck":
      pin = <PinCaminhao {...myStyle} />;
      break;
    case "package":
      pin = <PinEncomenda {...myStyle} />;
      break;
    case "arrow":
      pin = <PinSeta {...myStyle} />;
      break;
    default:
      pin = <PinPadrao {...myStyle} />;
      break;
  }
  const iconMarkup = renderToStaticMarkup(pin);
  const customMarketIcon = divIcon({
    html: iconMarkup,
    className: "dummy",
  });
  return customMarketIcon;
};

const splitMessages = (texto, messages) => {
  let ret=[];
  ret.push(texto);
  messages.split(',').forEach(text => {
    ret.push(text);
  })

  return ret;
}

export const getErrorMessageStr = (error) => {
  console.log('\n\n\n\ STATUS: '+ JSON.stringify(error.response))
  let res = "";

  if (
    error === undefined ||
    error.response === undefined ||
    error.response.status === undefined
  ) {
    console.log("ENTREI NO IF" + JSON.stringify(error));
    return "Erro desconhecido. >>" + error.message;
  }

  switch (error.response.status) {
    case 401:
      res = "Login ou senha não conferem. Ou esse usuário não tem permissão.";
      break;
    case 500:
      res = "Erro interno no servidor!";
      break;
    case 400:
      res = "Requisição não pode ser processada!";
      break;
    case 408:
      res = "Resposta não processado no tempo útil.!";
      break;
    case 422:
      res = splitMessages("Dados obrigatórios faltando: ", error.message);
      break;
    default:
      res = "Erro desconhecido. Verifique sua internet.";
      break;
  }
  console.log(res)
  //res += "(" + error.response.status + ")";
  return res;
};

//retorna uma posicao de exibicao em mapa, como o device e a position passada
export const getStandardPosition = (device, position) => {
  //console.log('device:\n' + JSON.stringify(device) + '\npositions:\n ' + JSON.stringify(position))

  return {
    id: position.id,
    key: "marker" + position.deviceId,
    text: device.name,
    position: {
      lat: position.latitude !== undefined ? position.latitude : "0", //zero pois sempre posição q foi passada pelo device
      lng: position.longitude !== undefined ? position.longitude : "0",
    },
    speed: position.speed,
    batteryLevel:
      position.attributes.batteryLevel !== undefined
        ? position.attributes.batteryLevel
        : null,
    battery:
      position.attributes.battery !== undefined
        ? position.attributes.battery
        : null,
    attributes: position.attributes,
    deviceId: device.id,
    category: device.category,
    lastUpdate: device.lastUpdate,
    category: device.category,
    status: device.status,
    phone: device.phone,
    adc1: position.attributes.adc1,
    tracking: position.tracking,
  };
};

//retorna o tipo da notificacao de maneira mais inteligivel para o usuario
export const getNotificacaoType = (type) => {
  let res = "";
  switch (type) {
    case "ignitionOn":
      res = "Ignição Ligada!";
      break;
    case "ignitionOff":
      res = "Ignição Desligada!";
      break;
    case "maintenance":
      res = "Manutenção";
      break;
    case "deviceOnline":
      res = "Online!";
      break;
    case "deviceOffline":
      res = "Offline!";
      break;
    case "deviceMoving":
      res = "Em movimento!";
      break;
    case "deviceStopped":
      res = "Parado!";
      break;
    case "driverChanged":
      res = "Mudança Identif. Mot.!";
      break;
    case "deviceUnknown":
      res = "Desc. ou Hibernando!";
      break;
    case "geofenceEnter":
      res = "Entrou na Cerca Elet.!";
      break;
    case "geofenceExit":
      res = "Saiu da Cerca Elet.!";
      break;
    case "movement":
      res = "Em Movimento!";
      break;
    case "overspeed":
      res = "Acima da Velocidade!";
      break;
    case "powerCut":
      res = "Sem alimentação Externa!";
      break;
    case "alarm":
      res = "Alarme!";
      break;
    default:
      res = type;
      break;
  }
  return res;
};

//retorna um alarme um pouco mais inteligivel
export const getAlrmeByAlarm = (alarm) => {
  let res = "";
  switch (alarm) {
    case "ignitionOn":
      res = "⚠️Ignição Ligada!";
      break;
    case "ignitionOff":
      res = "⚠️Ignição Desligada!";
      break;
    case "lowBattery":
      res = "⚠️Nível de Bateria caindo!";
      break;
    case "powerCut":
      res = "⚠️Alimentação ext. cortada!";
      break;
    case "overspeed":
      res = "⚠️Acima da velocidade!";
      break;
    case "powerOn":
      res = "⚠️Alimentação ext. conectada!";
      break;
    case "powerOff":
      res = "⚠️Ignição Desligada!";
      break;
    default:
      res = alarm;
      break;
  }
  return res;
};

export const isIsoDate = (str) => {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
  var d = new Date(str);
  return d.toISOString() === str;
};

export const Spinner = (params) => {
  return <CSpinner color={params.color} size={params.size || "sm"}></CSpinner>;
};

export const MeuLoading = (params) => {
  let localHeight = "80vh";
  //console.log(params)
  if (
    params != undefined &&
    params != null &&
    params.height != undefined &&
    params.height != null
  )
    localHeight = params.height;
  return (
    <CCol xl={12}>
      <CCard>
        <CCardBody>
          <CRow style={{ height: localHeight, textAlign: "center" }}>
            <CCol
              md="12"
              className="d-flex justify-content-center align-self-center"
            >
              <ReactLoading type="bars" color="#3b5998" />
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  );
};


export function verificaLogin(history,pagina) {
  console.log("verificando login")
  fetch(baseURL + "/api/session", {
    method: "GET"
  })
    .then((response) => {
      console.log("obteve retorno");
      console.log(response)
      if (response.status == 200) {
        console.log("logado");
        if(pagina!=null)
          history.push(pagina);
      }
      else {
        console.log("nao logado 1.. indo para /#/login");
        localStorage.clear();
       
        history.push('/login');
      }
    })
    .catch((error) => {
      console.log("erro");
      localStorage.clear();
      history.push('/login');
    })
}


export const getHoursMinutesByMillis = (ms) => {
  let tempTime = moment.duration(ms);
  if (tempTime > 0)
    return (
      tempTime.hours() + "h" + tempTime.minutes() + "m" + tempTime.seconds() + "s"
    );
  else
      return 0
};

export const getMinutesByMillis = (ms) => {
  let tempTime = moment.duration(ms);
  let result = tempTime.hours() * 60;
  result += tempTime.minutes();  
  return result >= 0 ? result : 0;
};

export const getMaxSpeed = (maxSpeed) => {
  return maxSpeed > 0 ? (maxSpeed * 1.852).toFixed(1) : 0;
};

export const getAverageSpeed = (averageSpeed) => {
  return averageSpeed > 0 ? (averageSpeed * 1.852).toFixed(1) : 0;
};

export const getGastoEstimadoEmReais = (consumo, fuelType, cfg) => {
  //console.log(consumo + ' ' + fuelType + ' ' + JSON.stringify(cfg))
  try {
    if (
      consumo !== null &&
      consumo !== undefined &&
      consumo > 0 &&
      fuelType !== undefined &&
      fuelType !== null
    ) {
      switch (fuelType) {
        case "gasoline":
          return (consumo * parseFloat(cfg.gasoline)).toFixed(2);
        case "diesel":
          return (consumo * parseFloat(cfg.diesel)).toFixed(2);
        case "alcohol":
          return (consumo * parseFloat(cfg.alcohol)).toFixed(2);
        default:
          return 0;
      }
    } else {
      return 0;
    }
  } catch (e) {
    console.log(e);
    return 0;
  }
};

//recursão
const soma = (inicio, periodo, odometro) => {
  let prox = inicio + periodo;
  console.log(
    `VARIAVEIS: i: ${inicio} - per: ${periodo} - o: ${odometro} prox: ${prox}`
  );
  if (prox >= odometro) {
    return prox.toFixed(0);
  } else {
    soma(prox, periodo, odometro);
  }
};

export const getProximaManuntencao = (odometro, inicio, periodo) => {
  // console.log(`VARIAVEIS: i: ${inicio} - per: ${periodo} - o: ${odometro}`)
  if (inicio >= odometro) {
    return (inicio / 1000).toFixed(0);
  } else {
    let finalResult = soma(inicio, periodo, odometro);
    return (finalResult / 1000).toFixed(0);
  }
};

export const randomNumber = (min, max) => {
  return Math.random() * (max - min) + min;
};


export const getDaysBetweenDates = (startDate, stopDate) => {
  let dateArray = [];
  let currentDate = moment(startDate);
  let stopDate2 = moment(stopDate);
  while (currentDate <= stopDate2) {
      dateArray.push( moment(currentDate).format('DD/MM/YY') )
      currentDate = moment(currentDate).add(1, 'days');
  }
  return dateArray;
}
