const initialState = {
  maintenanceCRUD: {},
  maintenances: [],
  maintenancesByDevice: [], // usado no positionDetatail.jsx
};

const maintenanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case "MAINTENANCE_CRUD_SETTED":
      //action.payload.start = action.payload.start/1000
      //action.payload.period = action.payload.period/1000

      return { ...state, maintenanceCRUD: action.payload };
    case "MAINTENANCE_CRUD_REMOVED":
      return { ...state, maintenanceCRUD: action.payload };
    case "MAINTENANCE_CRUD_UPDATED":
      return { ...state, maintenanceCRUD: action.payload };
    case "MAINTENANCE_CRUD_CREATED":
      return { ...state, maintenanceCRUD: action.payload };

    case "FETCH_MAINTENANCES_BY_USER":
      return { ...state, maintenances: action.payload };
    case "FETCH_MAINTENANCES_BY_DEVICE":
      return { ...state, maintenancesByDevice: action.payload };
    default:
      return state;
  }
};

export default maintenanceReducer;
