import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import 'core-js';
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { baseURL } from '../src/utils/util';

import { icons } from './assets/icons'

import store from './store'

import { Provider } from 'react-redux'
// import thunk from 'redux-thunk'
// import multi from 'redux-multi'
// import promise from 'redux-promise'
// import reducers from './reducers'
// import { applyMiddleware, createStore } from 'redux';

// const devTools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()

// const store = applyMiddleware(multi, promise, thunk)(createStore)(reducers, devTools)





React.icons = icons

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();


